<template>
  <div class="app">
    <navbar-component></navbar-component>
    <service-component></service-component>
    <course-component></course-component>
    <contect-component></contect-component>
    <video-component></video-component>
    <footer class="container text-center footer">Copyright 2023</footer>
  </div>
</template>

<script>
import navbarComponent from './components/navbarComponent.vue'
import serviceComponent from './components/serviceComponent.vue'
import courseComponent from './components/courseComponent.vue'
import contectComponent from './components/contectComponent.vue'
import videoComponent from './components/videoComponent.vue'
export default {
  components: {
    navbarComponent,
    serviceComponent,
    courseComponent,
    contectComponent,
    videoComponent
  }
}
</script>

<style scoped>


.footer{
  background: #333;
  border-radius: 1rem;
  color: #fff;
}

</style>