<template>
  <div>
    <section class="container">
      <div class="card mt-3">
        <div class="card-title">
          <div class="mt-3 text-center">
            <h2>Serivces Game Bot</h2>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-bordered table-striped">
            <thead class="text-center">
              <tr>
                <th>#</th>
                <th>เกมส์</th>
                <th>รูปเกมส์</th>
                <th>ลิ้งดาวน์โหลด</th>
                <th>เวอร์ชั่น</th>
                <th>รายละเอียด</th>
                <th>ราคา</th>
                <th>วันที่อัพเดท</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr v-for="(service, index) in services" :key="index">
                <td>{{ service.id }}</td>
                <td>{{ service.game }}</td>
                <td style="width: 150px">
                  <img :src="service.img" :alt="service.game" />
                </td>
                <td>
                  <a :href="service.link">Download</a>
                </td>
                <td>{{ service.version }}</td>
                <td>{{ service.content }}</td>
                <td>{{ service.price }}บาท 30Days</td>
                <td>{{ moment(service.date).format("DD-MM-YYYY") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "serviceComponent",
  data() {
    return {
      services: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const res = await axios.get(
          "https://api-tdedbot.onrender.com/api/service"
        );
        if (res) {
          this.services = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    moment() {
      return moment();
    },
  },
};
</script>

<style scoped>
img {
  width: 70%;
}
th,
td {
  padding: 8px;
}
</style>