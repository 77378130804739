<template>
    <div>
      <section class="container">
        <div class="card mt-3">
          <div class="card-title">
            <div class="mt-3 text-center">
              <h2>Serivces Share Course Programming</h2>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-bordered table-striped">
              <thead class="text-center">
                <tr>
                  <th>#</th>
                  <th>หัวข้อ</th>
                  <th>เนื้อหา</th>
                  <th>ราคา</th>
                  <th>วันที่อัพเดท</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="(course, index) in courses" :key="index">
                  <td>{{ course.id }}</td>
                  <td>{{ course.title }}</td>
                  <td>{{ course.content }}</td>
                  <td>{{ course.price }}บาท</td>
                  <td>{{ moment(course.date).format("DD-MM-YYYY") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import moment from "moment";
  export default {
    name: "courseComponent",
    data() {
      return {
        courses: [],
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      async getData() {
        try {
          const res = await axios.get(
            "https://api-tdedbot.onrender.com/api/course"
          );
          if (res) {
            this.courses = res.data;
          }
        } catch (e) {
          console.log(e);
        }
      },
      moment() {
        return moment();
      },
    },
  };
  </script>
  
  <style scoped>
  img {
    width: 70%;
  }
  th,
  td {
    padding: 8px;
  }
  </style>