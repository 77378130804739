<template>
  <div>
    <section class="container">
      <div class="card mt-3">
        <div class="card-title">
          <div class="mt-3 text-center">
            <h2>Video List</h2>
          </div>
        </div>
        <div class="card-body">
            <table class="table table-bordered table-striped">
              <thead class="text-center">
                <tr>
                  <th>ลิ้งตัวอย่างบอท</th>
                  <th>หัวข้อ</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="(url, index) in urls" :key="index">
                  <td>
                    <iframe width="300" height="150" :src="url.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </td>
                  <td>{{ url.detail }}</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
import axios from "axios";
import moment from "moment";
export default {
  name: "videoComponent",
  data() {
    return {
      urls: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const res = await axios.get(
          "https://api-tdedbot.onrender.com/api/url_video"
        );
        if (res) {
          this.urls = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    moment() {
      return moment();
    },
  },
};
</script>
  
  <style scoped>
img {
  width: 70%;
}
th,
td {
  padding: 8px;
}
</style>