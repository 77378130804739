<template>
  <div>
    <section class="navbar container">
      <div class="logo">
        <h1>
          TDEDBOT
          <span class="material-symbols-outlined icon">
            settings_applications
          </span>
        </h1>
      </div>
      <ul>
        <li>
          <a href="#">
            <span class="material-symbols-outlined icon menu">
            home
          </span>
          Home
          </a>
        </li>
        <li><a href="https://www.youtube.com/watch?v=qO7gDDAgJOY" target="blank">
          <span class="material-symbols-outlined icon menu">
            Terminal
          </span>
          How to
        </a>
      </li>
        <li><a href="https://www.tdedbot.com/game/login">
          <span class="material-symbols-outlined icon menu">
            Login
          </span>
          Login
        </a>
      </li>
        <li><a href="https://www.tdedbot.com/game/register">
          <span class="material-symbols-outlined icon menu">
            how_to_reg
          </span>
          Register
        </a>
      </li>
      </ul>
    </section>

    <header class="container mt-3">
      <div class="header-info">
        <h1>ยินดีต้อนรับเข้าสู่โลกของบอท</h1>
        <p>
          TDEDBOT ผู้ให้บริการด้านบอทเกมส์ Mobile on emulator <br />ยาวนานกว่า
          3ปี มี Users ใช้งานมากกว่า 3,000คน
        </p>
      </div>
    </header>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
body {
  margin: 0px;
  padding: 0px;
}

.icon {
  display: inline-flex;
  vertical-align: bottom;
  margin-bottom: 8px;
}

.menu {
  font-size: 16px;
}
.navbar {
  display: flex;
  background-color: #333;
  color: #fff;
  border-radius: 1rem;
}

header {
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("https://images.unsplash.com/photo-1547394765-185e1e68f34e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-size: cover;
  border-radius: 1rem;
}

.header-info {
  width: 40.5rem;
  height: 15rem;
  text-align: center;
  color: rgb(255, 255, 255);
}

.header-info h1 {
  font-size: 3rem;
}

.header-info p {
  font-size: 1.1rem;
  color: rgb(205, 255, 6);
  font-weight: 300;
  margin-top: 3rem;
}

.logo {
  width: 20%;
  display: flex;
  justify-content: center;
}


span {
  font-size: 2rem;
}

ul {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-top: 14px;
}

ul li {
  margin: 0 2rem;
  font-size: 1.2rem;
}

ul li a {
  color: #fff;
  text-decoration: none;
}

ul li a:hover {
  border: 2px solid #fff;
  padding: 0 3px;
  font-weight: bold;
  border-radius: 0.4rem;
}
</style>