<template>
  <div>
    <section class="container">
      <div class="card mt-3">
        <div class="card-title">
          <div class="mt-3 text-center">
            <h2>Contect Us</h2>
          </div>
        </div>
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <img
                  src="https://images.unsplash.com/photo-1580835239846-5bb9ce03c8c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=736&q=80"
                  alt=""
                />
              </div>
              <div class="col text-center">
                <h5 class="card-title">TDEDBOT Contect</h5>
                <h6 class="card-subtitle mb-2 text-muted">เวลาทำการ 09.00 - 00.00 ทุกวัน</h6>
                <p class="card-text">Line id : tded38</p>
                <button class="btn btn-outline-info" @click="fb()">Facebook</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
    methods: {
        fb(){
            location.href = "https://www.facebook.com/botezuse"
        }
    }
};
</script>

<style scoped>
img {
  width: 20%;
  border-radius: 1rem;
}
</style>